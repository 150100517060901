@import '../../../../vars.scss';

/* <- NewOrder-similarProductTable -> */
.ProductSelection-similarProductsTable {
	margin: 0;

	th.ProductSelection-similarProductsTable-field {
		width: 8.5%;
	}

	th.ProductSelection-similarProductsTable-icon {
		width: 21px;
	}


	th.ProductSelection-similarProductsTable-description {
		width: 35%;
		text-align: left;
	}

	th.ProductSelection-similarProductsTable-additions {
		width: 5%;
	}

	th.ProductSelection-similarProductsTable-dimension,
	th.ProductSelection-similarProductsTable-quantity,
	th.ProductSelection-similarProductsTable-price,
	th.ProductSelection-similarProductsTable-measurement-unit-quantity,
	th.ProductSelection-similarProductsTable-square-meters-quantity,
	th.ProductSelection-similarProductsTable-gross-price {
		text-align: right;
	}


	tbody tr {
		height: 45px;
	}

	.edit-td {
		padding: 0;
	}
	
	.number-input {
		justify-content: center;
		height: 35px;
		font-size: 200%;

		.btn {
			width: 30px;
		}

		input {
			min-width: 60px;
			width: 60px;
		}
	}
}

.SimilarProducts-collapse-arrow-box {
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
}

/* <- ProductSelection-clickableElem -> */
.ProductSelection-clickableElem {
	background-color: black;
	cursor: pointer;
	color: white;
	padding: 5px;
	border-radius: 5px;
	max-width: 400px;
	margin: 0 0 10px 0;
}

.ProductSelection-clickableElem:hover {
	background-color: #333;
}

/* <- ProductSelection-addProduct -> */
.ProductSelection-addProduct {
	background-color: #f1f1f1;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.ProductSelection-addProductHeader {
	display: flex;
	align-items: center;
	background-color: $brand;
	padding: 10px;
	margin: 15px 0 0 0;
	justify-content: space-between;
	height: 40px;
}

.ProductSelection-addProduct>div.row>div.col {
	padding: 5px;
}

.ProductSelection-addProduct .number-input {
	height: 40px;
	margin-top: 5px;
	justify-content: center;

	.btn {
		min-width: 40px;
		width: 40px;
	}

	input {
		width: 40px;
		min-width: 60px;
		width: 60px;
	}
}

.ProductSelection-addProduct .NewOrder-typeElem {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	padding: 10px;
	flex-wrap: wrap;
}

.ProductSelection-addProduct-desktop {
	border: 1px solid rgba(0, 0, 0, 0.125);
	width: 100%;
	> .ProductSelection-addProduct-desktop-header {
		font-size: 18px;
		padding: 0.25rem 0rem;
		> hr {
			margin: 0.25rem 0 0 0;
		}
	}
	> hr {
		margin: 0 0.5rem;
	}
}

.ProductSelection-addProduct-priceDisplay {
	display: grid;
	grid-template-columns: 1fr 100px;
	padding: 10px;
	align-items: center;

	> .ProductSelection-addProduct-priceDisplay-rowTitle {
		text-align: right;
	}

	> .ProductSelection-addProduct-priceDisplay-price {
		text-align: left;
		padding: 0 0 0 20px;
	}
}

.ProductSelection-addProduct-checkBox {
	margin-bottom: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	padding-right: 30px;

	> label {
		margin: 0;
		padding: 0 0 0 10px;
	}
}

/* <- ProductSelection-addToCart-mobile -> */
.ProductSelection-addToCart-mobile {
	display: flex;
	justify-content: center;
	align-items: center;

	>div {
		position: absolute;
		right: 5%;
		animation: transforming 2s ease alternate infinite;
	}
}

/* <- ProductSelection-SimilarProducts-headerData -> */
.ProductSelection-SimilarProducts-headerData .number-input {
	height: 40px;

	.btn {
		width: 40px;
	}

	input {
		min-width: 60px;
		width: 60px;
	}
}

/* MEDIA */
@media (max-width: 991.5px) {

	.breadcrumb {
		justify-content: center;
	}

	.ProductSelection-addProduct {
		> .ProductSelection-addProductHeader {
			margin: 0px;
			height: auto;
		}
	}

	/* <- ProductSelection-productAdditions -> */
	.ProductSelection-productAdditions {
		display: flex;
		flex-direction: row;
		justify-content: space-around;
	}

	/* <- ProductSelection-details -> */
	.ProductSelection-details {
		background-color: #e0e0e0;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 10px;
		border-radius: 4px;
	}

	.ProductSelection-detailsCollapse {
		padding-top: 10px;
	}

	/* <- ProductSelection-assortmentAdditional -> */
	.ProductSelection-assortmentAdditional {
		background-color: #f1f1f1;
	}

	.ProductSelection-assortmentAdditional div.col {
		padding: 6px 8px;
	}

	/* <- ProductSelection-collapseSimilar -> */
	.ProductSelection-collapseSimilar {
		background-color: #f1f1f1;
		text-align: left;
	}

	.ProductSelection-addProduct {
		border-radius: 4px;
		margin-top: 5px;
	}
}

@media (max-width: 551px) {

	/* <- ProductSelection-SimilarProducts-headerData -> */
	.ProductSelection-SimilarProducts-headerData {
		width: 100%;

		.number-input {

			margin-left: auto;
			margin-right: auto;
			width: 70%;
			height: 40px;

			.btn {
				width: 40%;
			}

			input {
				font-size: 120%;
				width: 60%;
			}
		}
	}
}

@media (max-width: 381px) {

	.ProductSelection-addProduct .NewOrder-typeElem {
		flex-direction: column;
	}

	.ProductSelection-addProduct-checkBox {
		justify-content: flex-start;
	}
}

.manage-cart-icon-edit:hover {
	color: $brand;
}

.similar-products-manage-cart-icon-delete:hover {
	color: red;
}

.manage-cart-icon-edit, .similar-products-manage-cart-icon-delete {
	cursor: pointer;
}
